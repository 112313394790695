
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    visible: Boolean,
    title: String,
    width: Number,
    height: Number,
    content: String,
    contentStyle: String,
    close: Function,
    onOK: Function,
    onCancel: Function,
  },
  emits: ["update:visible"],
  setup(props, ctx) {
    const modalVisible = computed(() => props.visible);
    const modalTitle = computed(() => props?.title || "");
    const modalContent = computed(() => props?.content || "");
    const modalContentStyle = computed(() => props?.contentStyle || "");
    const modalWidth = computed(() => props?.width || 595);
    const modalHeight = computed(() => props?.height || 223);
    const handleCloseModal = (e: Event) => {
      e.stopPropagation();
      props.close && props.close();
      ctx.emit("update:visible", false);
    };
    const handleConfirmOK = async () => {
      if (props?.onOK) {
        await props.onOK();
      }
      ctx.emit("update:visible", false);
    };
    const handleConfirmCancel = () => {
      ctx.emit("update:visible", false);
      props?.onCancel && props.onCancel();
    };
    return {
      modalVisible,
      modalTitle,
      modalContent,
      modalWidth,
      modalHeight,
      modalContentStyle,
      handleCloseModal,
      handleConfirmOK,
      handleConfirmCancel,
    };
  },
});
