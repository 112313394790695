// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/bj.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/rhythm/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".container[data-v-6b1ee3f2]{font-size:30px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%;height:100vh;width:100vw;display:flex;align-items:center;flex-direction:column}.container .board[data-v-6b1ee3f2]{display:flex;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%;width:88vw;height:88vh;margin-top:4vh;padding:5vh}.container .board .list-content[data-v-6b1ee3f2]{padding:10px;margin:50px 20px;width:90%;height:90%}", ""]);
// Exports
module.exports = exports;
