import { request } from "@/utils/request";
import servers from "@/config/servers";

export const getUserMelodyFiles = (params: any) => {
  return request("GET", `/teacher/userMelodyFiles`, servers.user, params);
};

export const getUserMelodyFileInfo = (id: number) => {
  return request("GET", `/teacher/userMelodyFiles/${id}`, servers.user);
};
