
import { getUserMelodyFiles } from "@/services/rhythm";
import {
  defineComponent,
  ref,
  onBeforeMount,
  onActivated,
  onMounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import ListContent from "./components/ListContent";
import HomeBackground from "@/components/common/HomeBackground";

export default defineComponent({
  name: "MusicRhythm",
  components: { ListContent, HomeBackground },
  setup() {
    const router = useRouter();
    const myQueryParams = ref({});
    const squareQueryParams = ref({});
    const myList = ref([]);
    const squareList = ref([]);
    const myListRef = ref();
    const squareListRef = ref();

    onBeforeMount(() => {
      // getMyList();
      // getSquareList();
    });
    const myListRefClosePop = async () => {
      console.log(56565656);
      myListRef.value.closePop();
    };
    const getMyList = async () => {
      console.log(44444);
      myListRef.value.getList(false, "parent");
      document.getElementById("list_1").scrollTop = 0;
      // const { data } = await getUserMelodyFiles({
      //   ...myQueryParams.value,
      //   type: 1,
      //   per_page: 5,
      // });
      // myList.value = data;
    };
    const getSquareList = async () => {
      const { data } = await getUserMelodyFiles({
        ...squareQueryParams.value,
        type: 2,
        per_page: 5,
      });
      squareList.value = data;
    };
    const handleBtnActivated = (item, type) => {
      let targetList;
      if (type === 1) {
        targetList = myList;
      } else {
        targetList = squareList;
      }
      targetList.value.forEach((i) => {
        if (i.id === item.id) {
          i.activated = true;
        }
      });
    };
    const handleBtnNormal = (item, type) => {
      let targetList;
      if (type === 1) {
        targetList = myList;
      } else {
        targetList = squareList;
      }
      targetList.value.forEach((i) => {
        if (i.id === item.id) {
          i.activated = false;
        }
      });
    };
    const handleClick = (item) => {
      router.push({
        path: `/musicRhythm/detail/${item.id}`,
      });
    };
    return {
      myList,
      squareList,
      handleBtnNormal,
      handleBtnActivated,
      handleClick,
      getMyList,
      getSquareList,
      myListRef,
      squareListRef,
      myListRefClosePop,
    };
  },
});
